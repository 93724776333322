<template>
  <div class="contractors">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="#006FC0"
    ></v-progress-linear>
    <div class="content">
      <div class="btn">
        <v-btn color="white" class="btn" @click="openUploadModal">
          <v-icon class="btn__icon">mdi-cloud-upload</v-icon>
          <span class="btn__text">Загрузить файлы</span>
        </v-btn>
      </div>

      <v-data-table
        :headers="headers"
        :items="contractors"
        show-expand
        disable-sort
        disable-pagination
        hide-default-footer
        v-show="contractors.length > 0"
      >
        <template v-slot:[`item.created_at`]="{ item }">{{
          new Date(item.created_at + "Z").toLocaleString("ru-Ru")
        }}</template>
        <template v-slot:[`item.files_count`]="{ item }">{{
          item.reconciliations_files.length
        }}</template>
        <template v-slot:[`item.all_files_processed`]="{ item }">{{
          item.all_files_processed ? "Да" : "Нет"
        }}</template>
        <template v-slot:[`item.reconciliations_count`]="{ item }">{{
          countContractors(item.reconciliations_files)
        }}</template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12" md="12">
                <FilesTable v-bind:files="item" />
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
    <!-- если нет файлов-->
    <div class="no-cards" v-show="contractors.length === 0">
      <div class="text">
        Для загрузки файлов нажмите кнопку “Загрузить”
      </div>
    </div>
    <upload-modal
      :show="showModal"
      @closed="showModal = false"
      @accepted="getContractors()"
    />
  </div>
</template>

<script>
import axios from "axios";
import FilesTable from "@/components/ReconciliationsFilesTable/ReconciliationsFilesTable.vue";
import UploadModal from "@/components/Reconciliations/UploadModal";

export default {
  components: {
    FilesTable,
    UploadModal,
  },
  computed: {
    headers() {
      return [
        {
          text: "Дата загрузки",
          value: "created_at",
        },
        { text: "Кол-во файлов", value: "files_count" },
        {
          text: "Все файлы обработаны",
          value: "all_files_processed",
        },
        { text: "Кол-во контрагентов", value: "reconciliations_count" },
      ];
    },
  },
  data() {
    return {
      contractors: [],

      loading: false,
      sliceValue: 50,
      scrollPosition: null,
      isSelecting: false,
      showModal: false,

      countContractors: (files) => {
        return files.reduce((sum, current) => {
          sum += current.reconciliations_count;
          return sum;
        }, 0);
      },
    };
  },
  methods: {
    openUploadModal() {
      this.showModal = true;
    },
    getContractors() {
      return new Promise((resolve, reject) => {
        axios
          .get("/reconciliations")
          .then((resp) => {
            this.contractors = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  created() {
    this.getContractors();
  },
};
</script>

<style lang="scss" scoped>
.contractors {
  position: relative;
  height: 100%;
  .content {
    padding: 87px 243px 118px 243px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.no-cards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
.btn {
  position: relative;
  z-index: 11;
  padding: 0 !important;
  align-self: flex-end;
  min-width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  &__icon {
    color: #006fc0 !important;
    font-size: 37px !important;
    margin: 0 11px !important;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #006fc0 !important;
    margin-right: 8px;
  }
}
</style>
