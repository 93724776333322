var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contractors"},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"#006FC0"}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"btn"},[_c('v-btn',{staticClass:"btn",attrs:{"color":"white"},on:{"click":_vm.openUploadModal}},[_c('v-icon',{staticClass:"btn__icon"},[_vm._v("mdi-cloud-upload")]),_c('span',{staticClass:"btn__text"},[_vm._v("Загрузить файлы")])],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.contractors.length > 0),expression:"contractors.length > 0"}],attrs:{"headers":_vm.headers,"items":_vm.contractors,"show-expand":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(new Date(item.created_at + "Z").toLocaleString("ru-Ru")))]}},{key:"item.files_count",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.reconciliations_files.length))]}},{key:"item.all_files_processed",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.all_files_processed ? "Да" : "Нет"))]}},{key:"item.reconciliations_count",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.countContractors(item.reconciliations_files)))]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('FilesTable',{attrs:{"files":item}})],1)],1)],1)]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.contractors.length === 0),expression:"contractors.length === 0"}],staticClass:"no-cards"},[_c('div',{staticClass:"text"},[_vm._v(" Для загрузки файлов нажмите кнопку “Загрузить” ")])]),_c('upload-modal',{attrs:{"show":_vm.showModal},on:{"closed":function($event){_vm.showModal = false},"accepted":function($event){return _vm.getContractors()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }