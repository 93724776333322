<template>
  <v-row justify="center">
    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      absolute
      top
      color="#006FC0"
    ></v-progress-linear>
    <v-dialog
      content-class="dialog"
      max-width="650"
      min-height="193"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <div class="text">
          Если вы хотите загрузить информацию из нескольких файлов, то
          загружайте все файлы сразу. После загрузки новых файлов информация из
          старых файлов перестанет быть доступна.
        </div>
        <v-form ref="form" v-model="form">
          <div v-for="(input, index) in files" :key="`fileInput-${index}`">
            <v-file-input
              v-model="input.file"
              :name="`files[${index}][file]`"
              placeholder="Нажмите, чтобы добавить файл"
              :disabled="isLoading"
            />
          </div>
          <v-btn
            class="submin-btn"
            text
            @click="addField"
            :disabled="isLoading"
          >
            Добавить файл
          </v-btn>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            style="height: 40px"
            color="black"
            text
            @click="cancel"
            :disabled="isLoading"
          >
            Отменить
          </v-btn>
          <v-btn
            class="submin-btn"
            text
            @click="submit"
            :disabled="isLoading || calcFiles() === 0"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  props: ["show"],
  data() {
    return {
      form: null,
      files: [{ file: null }],
      calcFiles: () => this.objectFilesToArray().length,
      isLoading: false,
    };
  },
  methods: {
    addField() {
      this.files.push({ file: null });
    },
    objectFilesToArray() {
      const data = this.files.reduce((result, item) => {
        if (item.file) {
          result.push(item.file);
        }
        return result;
      }, []);

      return data;
    },
    cancel() {
      this.files = this.files.filter((item) => item.file);
      if (Object.keys(this.files).length === 0) {
        this.files = [{ file: null }];
      }
      this.$emit("closed");
    },
    submit() {
      this.isLoading = true;

      const data = this.objectFilesToArray();

      let formData = new FormData();

      for (let i = 0; i < data.length; i++) {
        let file = data[i];

        formData.append("files", file);
      }

      return new Promise((resolve, reject) => {
        axios
          .post("/reconciliations", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            this.$emit("accepted");
            this.$emit("closed");
            this.files = [{ file: null }];

            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 650px !important;
    min-height: 193px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 37px 22px 16px 22px;
    &__actions {
      padding: 0 !important;
      margin-top: 17px;
    }
  }
  .text {
    margin-bottom: 20px;
  }
  .submin-btn {
    height: 40px;
    color: white;
    background: #4caf50;
    &[disabled] {
      cursor: default;
      background: #eee !important;
    }
  }
}
</style>
